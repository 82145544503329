.botones-categ{
    margin-top: 75px;
    padding-bottom: 150px;
}

.container-tarjetas-categ{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.tarjeta-categ{
    background-color: #0089cf;
    border-bottom: 3px solid #00adef;
    border-top: 3px solid #00adef;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 9px;
    margin-left: 9px;
    padding: 10px 15px;
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3), -1px -5px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all .3s ease;

}

.tarjeta-categ:hover{
    transform: scale(1.01);
}

.tarjeta-categ h2{
    color: white;
    font-weight: 100;
    font-size: 33px;
    max-width: 345px;
    margin: 5px;
    padding-top: 7px;
    padding-bottom: 7px;

}
@media(max-width: 714px){
    .tarjeta-categ h2{
        font-size: 23px;
    }
}

.tarjeta-categ.right h2{
    text-align: right;
}

.tarjeta-categ img{
    width: 100%;
    max-width: 350px;
    display: block;
    margin: 0 auto;
}