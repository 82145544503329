.slider-principal .item {
    height: calc(100vh - 85px);
    background-size: cover;
}

@media (max-height: 490px) {
    .slider-principal .item {
        background-size: auto;
    }
}

@media (max-width: 714px) {
    .slider-principal .item {
        background-position-x: -485px;
    }
}

@media (max-width: 400px) {
    .slider-principal .item {
        background-position-x: -751px;
    }
}

.slider-principal .owl-dots {
    position: relative;
    bottom: 44px;
    display: flex;
    width: 100%;
    justify-content: center;
    height: 0;
}

.slider-principal .owl-dot {
    border: 1px solid #0089cf;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin-right: 3px;
    margin-left: 3px;
}

.slider-principal .owl-dot.active {
    background-color: #0089cf;
}

.container-asistente-tec {
    color: white;
    max-width: 450px;
}

.container-asistente-tec h1 {
    font-size: 3.9rem;
    margin-top: 0px;
    margin-bottom: 20px;
    font-weight: 400;
}

.container-asistente-tec .linea {
    height: 4px;
    background-color: white;
    max-width: 220px;
    width: 100%;
}

.container-asistente-tec h2 {
    font-size: 1.8rem;
    font-weight: 200;
    margin-top: 20px;
    margin-bottom: 10px;
}

@media (max-width: 800px) {
    .container-asistente-tec h2 {
        font-size: 1.3rem;
    }
}

.container-logo-weg {
    margin-right: 27px;
}

.container-logo-weg img {
    margin-bottom: 80px;
    max-width: 320px;
}


.slider-principal button {
    background-color: #00adef;
    border: none;
    color: white;
    font-family: Montserrat;
    font-weight: 100;
    font-size: .8rem;
    padding: 13px 30px;
    cursor: pointer;
    box-shadow: 4px 9px 10px 1px #00000047;
    outline: none;
    transition: all ease 0.2s;
}


.slider-principal button:hover {
    transform: scale(1.02);
}

@media (max-width: 825px) {
    .container-asistente-tec h1 {
        font-size: 2rem;
    }

    .container-logo-weg img {
        margin-bottom: 20px;
        max-width: 220px;
    }

    .slider-principal button {
        margin-bottom: 80px;
    }
}
@media(max-height: 490px){
    .container-asistente-tec h1{
        font-size: 1.3rem;
        margin-top: 20px;
    }
    .container-asistente-tec h2{
        font-size: .8rem;
    }
    .container-logo-weg img {
        max-width: 130px!important;
    }
    .container-asistente-tec .linea{
        height: 2px;
    }
}

@media (max-width: 400px) {
    .container-logo-weg img {
        max-width: 189px;
    }
}

.container-portada {
    display: flex;
    align-items: center;
    height: 100%;
}

.container-portada h2 {
    color: white;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 5.1rem;
    max-width: 500px;
    margin-top: 0px;
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.46);
    margin-bottom: 15px;
}

@media (max-width: 715px) {
    .container-portada h2 {
        font-size: 3rem !important;
    }
}

@media (max-width: 445px) {
    .container-portada h2 {
        font-size: 1.3rem !important;
    }
}

.container-portada h3 {
    margin-top: 0;
    color: white;
    font-family: Montserrat;
    font-weight: 100;
    font-size: 4rem;
    text-shadow: 4px 5px 9px rgba(0, 0, 0, .7);
    margin-bottom: 15px;
}

.container-portada h3 b {
    font-weight: 300;
}

@media (max-width: 715px) {
    .container-portada h3 {
        font-size: 2.3rem;
    }
}

@media (max-width: 445px) {
    .container-portada h3 {
        font-size: 1.3rem;
    }
}

.certificado {
    display: none;
    position: fixed;
    top: 0;
    z-index: 99999999;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.53);
    /*display: flex;*/
    align-items: center;
}

.certificado .img-certificado {
    position: relative;
    display: block;
    margin: 0 auto;
    padding: 15px;
}

.certificado .img-certificado .cruz-cerrar {
    position: absolute;
    right: 28px;
    top: 14px;
    font-size: 30px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
}

.certificado .img-certificado img {
    max-width: 900px;
    width: 100%;
}

.container-portada h2 u {
    text-decoration: none;
    padding-bottom: 8px;
    border-bottom: 5px solid white;
}

@media (max-width: 480px) and (orientation: landscape){
    .slider-principal .owl-dots{
        bottom: 22px;
    }
    .slider-principal .owl-dot{
        width: 10px;
        height: 10px;
    }

    .container-logo-weg img {
        max-width: 44px!important;
    }

    .container-portada h2{
        font-size: 1.5rem!important;
    }

    .container-portada h3{
        font-size: 1.3rem!important;
    }
}