.contacto{
    padding-top: 110px;
    padding-bottom: 110px;
    background-color: #f5f5f5;
}

.contacto p.titulo{
    color: #007fcb;
    font-weight: 100;
    max-width: 478px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-bottom: 70px;
    padding: 0 15px;
}

.contacto h6{
    color: #007fcb;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 10px;
}

.contacto .form-fields{
    display: flex;
    flex-direction: column;
    padding-right: 45px;
}

@media(max-width: 714px){
    .contacto .form-fields{
        padding-right: 0px;
    }
}

.contacto .form-fields input, .contacto .form-fields textarea{
    margin-bottom: 15px;
    font-family: Montserrat;
    font-weight: 300;
    color: #091a2e;
    padding-bottom: 15px;
    padding-top: 15px;
    outline: none;
    border: 2px solid #d8e1e4;
    padding-left: 8px;
    font-size: 13px;
    resize: none;
    border-radius: 4px;
}

.contacto .form-fields input::placeholder, .contacto .form-fields textarea::placeholder{
    color: #999999;
}

.contacto button {
    background-color: #00adef;
    border: none;
    color: white;
    font-family: Montserrat;
    font-weight: 100;
    font-size: .8rem;
    padding: 13px 30px;
    cursor: pointer;
    box-shadow: 4px 9px 10px 1px #00000047;
    outline: none;
    transition: all ease 0.2s;
    max-width: 200px;
    width: 100%;
}


.contacto button:hover {
    transform: scale(1.02);
}

.infoAdicional{
    border-bottom: 1px solid #dfe6e8;
    padding-bottom: 7px;
}


.infoAdicional-container p{
    color: #999999;
    font-weight: 100;
}

.infoAdicional-container .pline{
    border-bottom: 1px solid #dfe6e8;
    padding-bottom: 15px;
}

.container-redes img{
    max-width: 35px;
    padding-right: 10px;
}

.lds-dual-ring {
    display: inline-block;
    width: 15px;
    height: 15px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 15px;
    height: 15px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #0089cf;
    border-color: #0089cf transparent #0089cf transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.white:after {
    content: " ";
    display: block;
    width: 15px;
    height: 15px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid white;
    border-color: white transparent white transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



p.mensaje{
    color: #0089cf;
    font-weight: 100;
}

