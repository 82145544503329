.productos-relacionados {
    background-image: url("../images/catalogo/bg_prodrel.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70px;
    border-top: 2px solid #00adef;
    padding-bottom: 130px;
}

.productos-relacionados h2 {
    color: white;
    font-size: 16px;
    font-weight: 200;
    border-bottom: 2px solid #00adef;
    padding-bottom: 9px;
    margin-bottom: 8px;
}

.productos-relacionados .grilla-productos{
    padding: 0;
    padding-top: 55px;
    justify-content: space-between;
}


.productos-relacionados .tarjeta-producto{
    margin: 6px;
}

@media(max-width: 881px){
    .productos-relacionados .grilla-productos{
        justify-content: center;
    }
}