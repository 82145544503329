.webinar {
    background-image: url("../../assets/images/mantenimiento/bg-teclado.png");
    background-image: -webkit-image-set(url("../../assets/images/mantenimiento/bg-teclado.webp") 1x);
    background-size: cover;
    width: 100%;
    position: relative;
    /*top: -1px;*/
    color: white;
    padding-top: 60px;
    padding-bottom: 70px;
    border-top: 2px solid #00adef;
    border-bottom: 2px solid #00adef;
}

@media (max-width: 714px) {
    .webinar {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.webinar .container-webinar {
    max-width: 330px;
    padding-left: 65px;
    padding-right: 65px;
}

#container-webinar2 {
    border-left: 2px solid #00adef;
}

@media (max-width: 714px) {
    .webinar .container-webinar {
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
    }

    #container-webinar2 {
        border-top: 2px solid #00adef;
        margin-top: 30px;
        border-left: none;
    }
}

.webinar .container-text p {
    font-weight: 150;
    font-size: 1.2rem;
    line-height: 26px;
}

.webinar button {
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #091a2e;
    border: none;
    color: white;
    font-family: Montserrat;
    height: 45px;
    font-weight: 200;
    font-size: .8rem;
    padding: 13px 30px;
    cursor: pointer;
    box-shadow: 4px 9px 10px 1px #00000047;
    outline: none;
    transition: all ease 0.2s;
}

.webinar button:hover {
    transform: scale(1.02);
}