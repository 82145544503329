.galeria-md .owl-carousel .owl-item img{
    width: auto!important;
}

.galeria .owl-carousel .owl-item img{
    width: 50%;
}

.galeria-responsive{
    display: none;
}
.galeria-xl{
    display: block;
}
@media(max-width: 714px) {
    .galeria-responsive{
        display: block;
    }
    .galeria-xl{
        display: none;
    }
}