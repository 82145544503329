.footer {
    background: #091a2e;
    padding: 20px 0;
    color: #FFF;
    font-family: Montserrat;
    font-weight: 100;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

.footer p {
    font-size: 10px;
    margin: 5px 0;
}

.footer .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.exadec{
    background-image: url("../images/exadec.png");
    background-repeat: no-repeat;
    background-size: 65%;
    width: 100px;
    height: 18px;
    position: relative;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    -ms-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

.desa{
    color: grey;
}
.desa:hover .exadec{
    background-image: url("../images/exadec2.png");
}
