.slider-plantas {
    background-color: #0089cf;
    background-size: cover;
    width: 100%;
    position: relative;
    /*top: -1px;*/
    box-shadow: inset 0px 0px 20px 0px #0000006b;
    color: white;
    padding-top: 30px;
    padding-bottom: 30px;
}

@media (max-width: 714px) {
    .slider-plantas {
        padding-top: 40px;
        padding-bottom: 50px;
    }
}

.slider-plantas .container-titulo h3 {
    text-align: center;
    font-weight: 100;
    font-size: calc(2.4rem - 1vmax);
}

.slider-plantas .tarjeta-cuerpo p {
    font-family: 'PT Sans', 'Source Sans Pro', sans-serif;
    font-weight: 100;
    font-size: 0.96rem;
    text-align: center;
    line-height: 26px;
}

nav.nav-plantas {
    overflow-x: auto;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
}

@media (max-width: 714px) {
    nav.nav-plantas {
        margin-bottom: 0;
    }
}

nav.nav-plantas ul {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;
}

nav.nav-plantas ul li {
    list-style: none;
    min-height: 30px;
}

nav.nav-plantas ul li p {
    color: white;
    text-decoration: none;
    font-weight: 100;
    font-size: .9rem;
    display: flex;
    align-items: center;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;
}

@media (max-width: 714px) {
    nav.nav-plantas {
        mask-image: linear-gradient(to right, transparent, white 20px, white 90%, transparent)
    }

    nav.nav-plantas ul {
        width: max-content;
        justify-content: inherit;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    nav.nav-plantas ul li p {
        font-size: 1rem;
        padding: 0;
        margin-left: 15px;
        margin-right: 15px;
    }

    nav.nav-plantas ul li p:first-child {
        margin-left: 0;
    }
}

nav.nav-plantas ul li p:hover, nav.nav-plantas ul li p.selected {
    border-bottom: 3px solid #1cccef;
}

.container-info-planta {
    padding-right: 20px;
}

@media (max-width: 714px) {
    .container-info-planta {
        padding-right: 0;
    }
}

.container-info-planta p.cuerpo {
    font-weight: 150;
    line-height: 20px;
    font-size: .95rem;
}


.planta-container-btn {
    display: flex;
    flex-direction: column;
    max-width: 220px;
    margin-top: 50px;
}

@media (max-width: 714px) {
    .planta-container-btn {
        margin: 0 auto;
        margin-bottom: 14px;
    }
}

.planta-container-btn button {
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: white;
    border: none;
    color: #00adef;
    font-family: Montserrat;
    height: 45px;
    font-weight: 200;
    font-size: .8rem;
    padding: 13px 30px;
    cursor: pointer;
    box-shadow: 4px 9px 10px 1px #00000047;
    outline: none;
    transition: all ease 0.2s;
}

.planta-container-btn button:hover {
    transform: scale(1.02);
}

.slider-plantas .galeria-img {
    padding-left: 70px;
    padding-right: 0;
    padding-top: 30px;
    cursor: pointer;
    padding-bottom: 0;
}

.slider-plantas .galeria-img .img-principal {
    background-color: white;
    text-align: center;
    margin: 5px;
    margin-bottom: 0px;
    border: 2px solid #00adef;
    border-bottom: 2px solid #00adef;
}

.slider-plantas .galeria-img .img-principal img {
    width: 100%;
    height: auto;
}

.slider-plantas .galeria-img .img-thumb {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    margin-top: 0px;
}

.slider-plantas .galeria-img .img-thumb .thumb {
    background-color: white;
    border: 2px solid #00adef;
    margin-left: 0px;
    margin-right: 0px;
}

.slider-plantas .galeria-img .img-thumb .thumb:first-child {
    margin-left: 0px;
}

.slider-plantas .galeria-img .img-thumb .thumb:last-child {
    margin-right: 0px;
}

.slider-plantas .galeria-img .img-thumb .thumb img {
    width: 100%;
    height: 100%;
}

@media (max-width: 714px) {
    .slider-plantas .galeria-img {
        display: inherit;
        padding: inherit;
    }
}

.container-nav-car {
    display: flex;
    position: absolute;
    width: 100%;
    top: calc(50% - 100px);
    justify-content: space-between;
}

@media (max-width: 1100px) {
    .container-nav-car {
        display: none;
    }
}

.container-nav-car img {
    max-width: 30px;
    padding-right: 15px;
    padding-left: 15px;
    cursor: pointer;
}

