.header-titulo{
    background-color: #091a2e;
    width: 100%;
    position: relative;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-titulo h3{
    text-align: center;
    font-size: 2rem;
    font-weight: 150;
    /*padding-bottom: 37px;*/
}

@media(max-width: 815px){
    .header-titulo h3{
        font-size: 1.2rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
}

.header-titulo h3 b{
    font-weight: 300;
}