.analisisEstaticos {
    background-color: #00adef;
    width: 100%;
    position: relative;
    color: white;
    padding-top: 40px;
    padding-bottom: 40px;
}

@media (max-width: 714px) {
    .analisisEstaticos {
        padding-top: 40px;
        padding-bottom: 60px;
    }
}

.analisis .container-titulo h3 {
    text-align: center;
    font-weight: 150;
    font-size: calc(2.4rem - 1vmax);
}

@media (max-width: 714px) {
    .analisis .container-titulo h3 {
        font-size: calc(2.4rem - 1.8vmax);
    }

}

.analisis .container-titulo h3 b {
    font-weight: 300;
}

.analisis .container-text {
    padding: 25px;
}

.analisis .container-text p {
    font-family: 'PT Sans', 'Source Sans Pro', sans-serif;
    font-weight: 200;
    font-size: 0.96rem;
    line-height: 26px;
}

.analisis .container-text b {
    font-family: 'PT Sans', 'Source Sans Pro', sans-serif;
    font-weight: 800;
    font-size: 0.96rem;
}

@media (max-width: 714px) {
    .analisis .container-text {
        padding-bottom: 3px;
        padding-top: 3px;
    }

    .analisis .container-text p {
        margin: 0;
        margin-bottom: 10px;
    }
}

.ensayosDisponibles {
    padding-top: 30px;
    padding-bottom: 70px;
}

.ensayosDisponibles .container-titulo h4 {
    text-align: center;
    color: #00adef;
    font-weight: 200;
    margin-bottom: 60px;
}

.ensayosDisponibles ul {
    padding-right: 80px;
    color: #00adef;
    margin-top: 0;
}

@media (max-width: 714px) {
    .ensayosDisponibles ul {
        padding-right: 15px;
    }
}

.ensayosDisponibles ul li:first-child {
    padding-top: 0;
}

.ensayosDisponibles ul li:last-child {
    /*border-bottom: none;*/
}

.ensayosDisponibles ul li {
    border-bottom: 1px solid #00adef;
    padding-bottom: 8px;
    padding-top: 15px;
    font-size: 0.96rem;
}