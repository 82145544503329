.clientes{
    padding-top: 70px;
    padding-bottom: 70px;
}

.clientes h3{
    color: black;
    text-align: center;
    font-weight: 100;
    font-size: calc(2.4rem - 1vmax);
}

@media(max-width: 815px){
    .clientes h3{
        font-size: 1.5rem;
        margin: 0;
    }
}

.clientes h3 b{
    font-weight: 300;
}


.clientes .item{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 260px;
    padding: 38px;
}

.clientes .owl-dot {
    border: 1px solid black;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 3px;
    margin-left: 3px;
}

.clientes .owl-dot.active{
    background-color: black;
}