@import "Fonts.css";

body {
  margin: 0;
  font-family: "Montserrat";
  font-weight: 200;
  font-size: medium;
}

.react-photo-gallery--gallery div {
    flex-flow: nowrap !important;
    overflow-x: auto !important;
}

body.no-scroll{
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-limit{
  max-width: 1070px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.container-doble {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.col-6 {
  width: 50%;
}

.col-6-fixed{
    width: 440px;
}

.col-3{
    width: 30%;
}

.col-9{
    width: 70%;
}

@media (max-width: 714px) {
    .col-6 {
        width: 100%;
    }

    .col-3{
        width: 100%;
    }

    .col-9{
        width: 100%;
    }
}