.monitoreoVibraciones {
    background-color: #cfdee6;
    width: 100%;
    position: relative;
    color: #0089cf;
    padding-top: 40px;
    padding-bottom: 40px;
}

@media (max-width: 714px) {
    .monitoreoVibraciones {
        padding-top: 40px;
        padding-bottom: 60px;
    }
}