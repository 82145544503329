.analisisDinamico {
    width: 100%;
    background-color: #FFFFFF;
    position: relative;
    color: #0089cf;
    padding-top: 40px;
    padding-bottom: 40px;
}

@media (max-width: 714px) {
    .analisisDinamico {
        padding-top: 40px;
        padding-bottom: 60px;
    }
}

.analisis ul {
    padding-right: 80px;
    color: #0089cf;
    padding-left: 20px;
}

@media (max-width: 714px) {
    .analisis ul {
        padding-right: 15px;
    }
}

.analisis ul li:first-child {
    padding-top: 0;
}

.analisis ul li:last-child {
    /*border-bottom: none;*/
}

.analisis ul li {
    border-bottom: 1px solid #0089cf;
    padding-bottom: 8px;
    padding-top: 15px;
    font-size: 0.96rem;
}

.analisis-btn-container {
    display: flex;
    flex-direction: column;
    max-width: 220px;
    margin-top: 50px;
}

@media (max-width: 714px) {
    .analisis-btn-container {
        margin: 0 auto;
        margin-bottom: 14px;
    }
}

.analisis-btn-container button {
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #0089cf;
    border: none;
    color: white;
    font-family: Montserrat;
    height: 45px;
    font-weight: 200;
    font-size: .8rem;
    padding: 13px 30px;
    cursor: pointer;
    box-shadow: 4px 9px 10px 1px #00000047;
    outline: none;
    transition: all ease 0.2s;
}

.analisis-btn-container button:hover {
    transform: scale(1.02);
}

.analisis .galeria-img {
    padding-left: 0px;
    padding-right: 0;
    padding-top: 30px;
    cursor: pointer;
    padding-bottom: 0;
}

.analisis .galeria-img .img-principal {
    background-color: white;
    text-align: center;
    margin: 5px;
    margin-bottom: 0px;
    border: 2px solid #00adef;
    border-bottom: 2px solid #00adef;
}

.analisis .galeria-img .img-principal img {
    width: 100%;
    height: auto;
}

.analisis .galeria-img .img-thumb {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    margin-top: -5px;
}

.analisis .galeria-img .img-thumb .thumb {
    background-color: white;
    border: 2px solid #00adef;
    margin-left: 0px;
    margin-right: 0px;
}

.analisis .galeria-img .img-thumb .thumb:first-child {
    margin-left: 0px;
}

.analisis .galeria-img .img-thumb .thumb:last-child {
    margin-right: 0px;
}

.analisis .galeria-img .img-thumb .thumb img {
    width: 100%;
    height: 100%;
}

@media (max-width: 714px) {
    .analisis .galeria-img {
        display: inherit;
        padding: 0;
    }
}