.sobreNosotros {
    background-image: url("../../assets/images/bgSobreNosotros.png");
    background-size: cover;
    width: 100%;
    position: relative;
    color: white;
    padding-top: 100px;
    padding-bottom: 160px;
}

.sobreNosotros h3 {
    text-align: center;
    font-size: calc(5rem - 2vmax);
    font-weight: 150;
    margin-bottom: 90px;
}

@media (max-width: 815px) {
    .sobreNosotros h3 {
        font-size: 1.5rem;
        margin-bottom: 5px;
    }
}

.sobreNosotros h3 b {
    font-weight: 300;
}

.sobreNosotros .container-doble {
    padding-top: 30px;
    font-weight: 100;
    font-family: 'PT Sans', 'Source Sans Pro', sans-serif;
}

.sobreNosotros .container-text p {
    padding-left: 35px;
    padding-right: 35px;
    line-height: 35px;
}

@media (max-width: 714px) {
    .sobreNosotros .container-text p {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.sobreNosotros ul {
    padding-right: 80px;
}

@media (max-width: 714px) {
    .sobreNosotros ul {
        padding-right: 15px;
    }
}

.sobreNosotros ul li:first-child {
    padding-top: 0;
}

.sobreNosotros ul li:last-child {
    border-bottom: none;
}

.sobreNosotros ul li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.79);
    padding-bottom: 8px;
    padding-top: 15px;
}

.dsc-SobreNosotros {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #091a2e;
    color: white;
}

.dsc-SobreNosotros .container-titulo {
    display: flex;
    padding: 20px 27px;
    align-items: center;
}

.dsc-SobreNosotros .container-titulo img {
    max-width: 55px;
    max-height: 55px;
}

.dsc-SobreNosotros .container-titulo h6 {
    margin-left: 15px;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
}

.dsc-SobreNosotros .container-cuerpo {
    padding: 10px 27px;
}

@media (max-width: 714px) {
    .dsc-SobreNosotros .container-cuerpo {
        padding: 10px 15px;
    }
}

.dsc-SobreNosotros .container-cuerpo p {
    font-family: 'PT Sans', 'Source Sans Pro', sans-serif;
    font-weight: 100;
    font-size: 15px;
    max-width: 460px;
    line-height: 28px;
}