.datos-contacto {
    padding-top: 60px;
    padding-bottom: 40px;
    background-color: #007fcb;
}

.container-dato {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media (max-width: 780px) {
    .container-dato {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

.datos-contacto .dato {
    max-width: 250px;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-bottom: 30px;
}


.datos-contacto .dato img {
    max-width: 60px;
}

.datos-contacto .dato .dsc-datos{
    text-align: center;
}

.datos-contacto .dato .dsc-datos h6{
    font-size: 16px;
    margin: 15px 0px;
    font-weight: 300;
}
.datos-contacto .dato .dsc-datos a{
    color: white;
    text-decoration: none;
    font-weight: 100;
    font-size: 14px;
}

@media(max-width: 714px){
    .datos-contacto .dato{
        flex-direction: row;
    }

    .datos-contacto .dato .dsc-datos{
        margin-left: 15px;
        text-align: left;
    }

    .datos-contacto .dato .dsc-datos h6{
        margin-top: 3px;
        margin-bottom: 3px;
    }
    .datos-contacto .dato .dsc-datos a{
    }
}

.mapa{
    height: 500px;
}

.marker-map{
    max-width: 35px;
    width: 30px!important;
    height: 30px!important;
}
.link-marker{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.link-marker p{
    text-decoration: none;
    color: #091a2e;
    font-family: Montserrat;
    font-size: 13px;
    margin-top: 3px;
}