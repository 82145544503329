.beneficios {
    background-image: url("../../assets/images/mantenimiento/bg-beneficios.png");
    background-image: -webkit-image-set(url("../../assets/images/mantenimiento/bg-beneficios.webp") 1x);
    background-size: cover;
    width: 100%;
    position: relative;
    /*top: -1px;*/
    box-shadow: inset 0px 0px 20px 0px #0000006b;
    color: white;
    padding-top: 60px;
    padding-bottom: 130px;
}

.beneficios .container-titulo h3 {
    text-align: center;
    font-weight: 100;
    font-size: calc(2.4rem - 1vmax);
}

.beneficios .container-titulo h3 b {
    font-weight: 300;
}

.beneficios .container-tarjetas {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.beneficios .tarjeta-beneficio {
    max-width: 320px;
    width: 100%;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 10px solid #00adef;
    transition: all ease .2s;
}

.beneficios .tarjeta-beneficio:hover {
    transform: scale(1.01);
}

.beneficios .tarjeta-titulo {
    margin-bottom: 10px;
    background-color: white;
    color: #00adef;
}

@media (max-width: 714px) {
    .beneficios .tarjeta-titulo {
        margin-bottom: -30px;
    }
}

.beneficios .tarjeta-titulo-flex {
    display: flex;
    padding: 29px 32px;
    align-items: center;
    flex-direction: column;
    border-bottom: none;
    justify-content: center;
}

.beneficios .tarjeta-titulo img {
    width: 55px;
    height: 55px;
    object-fit: contain;
}

.beneficios .tarjeta-titulo h6 {
    font-size: 0.9rem;
    font-weight: 400;
    margin: 0;
    margin-bottom: 0px;
    margin-top: 15px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
}

.beneficios .tarjeta-cuerpo {
    padding: 10px 50px;
    padding-bottom: 25px;
    background-color: white;
    color: #00adef;
}

.beneficios .tarjeta-cuerpo ul {
    font-family: PT Sans, Source Sans Pro, sans-serif;
    font-weight: 100;
    font-size: .96rem;
}

.beneficios .tarjeta-cuerpo p {
    font-family: 'PT Sans', 'Source Sans Pro', sans-serif;
    font-weight: 100;
    font-size: 0.96rem;
    text-align: center;
    line-height: 26px;
}