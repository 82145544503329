.servicios{
    background-image:url("../../assets/images/bgServicios.png");
    background-size: cover;
    width: 100%;
    position: relative;
    /*top: -1px;*/
    box-shadow: inset 0px 0px 20px 0px #0000006b;
    color: white;
    padding-top: 90px;
    padding-bottom: 130px;
}

.servicios .container-titulo h3{
    text-align: center;
    font-weight: 150;
    font-size: calc(2.4rem - 1vmax);
}

.servicios .container-titulo h3 b{
    font-weight: 300;
}

.servicios .container-parrafo{
    max-width: 800px;
    margin: 10px auto;
}
.servicios .container-parrafo p{
    text-align: center;
    font-weight: 100;
}

@media(max-width: 815px){
    .servicios .container-titulo h3{
        font-size: 1.5rem;
    }
    .servicios .container-parrafo p{
        font-size: 12px;
        padding: 0 15px;
    }
}

.servicios .container-tarjetas{
    margin-top: 70px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.servicios .tarjeta-servicio{
    max-width: 320px;
    width: 100%;
    background-color:#091a2e;
    margin-bottom: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 10px solid #00adef;
    transition: all ease .2s;
}

.servicios .tarjeta-servicio:hover{
    transform: scale(1.01);
}

.servicios .tarjeta-titulo{
    border-bottom: 2px solid #00adef;
}

.servicios .tarjeta-titulo-flex{
    display: flex;
    padding: 20px 27px;
    align-items: center;
}

.servicios .tarjeta-titulo img{
    max-width: 55px;
    max-height: 55px;
}

.tarjeta-titulo h6{
    margin-left: 15px;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
}

.servicios .tarjeta-cuerpo{
    padding: 10px 25px;
    padding-bottom: 25px;
}

.servicios .tarjeta-cuerpo ul{
    font-family: PT Sans,Source Sans Pro,sans-serif;
    font-weight: 100;
    font-size: .96rem;
}

.tarjeta-cuerpo p{
    font-family: 'PT Sans', 'Source Sans Pro', sans-serif;
    font-weight: 100;
    font-size: 0.96rem;
}