.producto {
    background-color: #0089cf;
    box-shadow: inset 0 5px 20px #00000040;
    padding-top: 65px;
    padding-bottom: 65px;
}

.breadcrumb {
    margin-bottom: 50px;
}

.breadcrumb ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.breadcrumb ul li {
    margin-left: 5px;
    font-weight: 100;
    color: #00b3ef;
}

.breadcrumb ul li a {
    color: #00b3ef;
    text-decoration: none;
}


.breadcrumb ul li:first-child {
    margin-left: 0px;
}

@media (max-width: 714px) {
    .breadcrumb {
        margin-bottom: 15px;
    }

    .breadcrumb ul li {
        font-size: 9px;
    }

    .producto {
        padding-top: 25px;
    }
}


.breadcrumb ul li:after {
    content: '>';
    width: 10px;
    height: 10px;
}

.breadcrumb ul li:last-child {
    font-weight: 200;
}

.breadcrumb ul li:last-child:after {
    content: '';
}

.producto h2 {
    color: white;
    font-size: 16px;
    font-weight: 200;
    max-width: 310px;
    margin-bottom: 8px;
}

.producto h3 {
    color: white;
    font-size: 15px;
    font-weight: 200;
    margin-top: 3px;
    display: initial;
    padding-bottom: 3px;
    border-bottom: 3px solid #00ace9;
}


.producto h6 {
    color: white;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 0px;
}

.producto p {
    margin-top: 4px;
    color: white;
    font-weight: 100;
    font-size: 12px;
    max-width: 350px;
    line-height: 16px;
    margin-bottom: 55px;
}

.producto p b {
    font-weight: 300;
}

.container-logo-boton {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-logo-boton.res {
    display: none;
    margin-top: 25px;
}

@media (max-width: 714px) {
    .container-logo-boton {
        display: none;
    }

    .container-logo-boton.res {
        display: flex;
    }

}

.container-logo-boton img {
    max-width: 100px;
    margin-right: 5px;
}

.container-logo-boton button {
    background-color: #00adef;
    border: none;
    color: white;
    font-family: Montserrat;
    height: 45px;
    font-weight: 100;
    font-size: .8rem;
    padding: 13px 30px;
    cursor: pointer;
    box-shadow: 4px 9px 10px 1px #00000047;
    outline: none;
    transition: all ease 0.2s;
    margin-left: 5px;
}

.container-logo-boton button:hover {
    transform: scale(1.02);
}

.galeria-img {
    padding: 85px;
    padding-top: 0px;
}

.galeria-img .img-principal {
    background-color: white;
    text-align: center;
    margin: 5px;
    margin-bottom: 0px;
    border: 2px solid #00adef;
    border-bottom: 2px solid #00adef;
}

.galeria-img .img-principal img {
    width: 70%;
    height: auto;
}

.galeria-img .img-thumb {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    margin-top: 0px;
}

.galeria-img .img-thumb .thumb {
    background-color: white;
    border: 2px solid #00adef;
    margin-left: 0px;
    margin-right: 0px;
}

.galeria-img .img-thumb .thumb:first-child {
    margin-left: 0px;
}

.galeria-img .img-thumb .thumb:last-child {
    margin-right: 0px;
}

.galeria-img .img-thumb .thumb img {
    width: 100%;
    height: 100%;
}

.galeria-img.res {
    display: none;
    padding: 0px;
}

.galeria-img.res .img-principal {
    margin: 0;
    display: flex;
    overflow-x: auto;
}

.galeria-img.res .img-big {
    width: 100vw;
}

.galeria-img.res .img-big img {
    width: inherit;
    margin: 0 auto;
    display: block;
}

@media (max-width: 714px) {
    .galeria-img {
        display: none;
    }

    .galeria-img.res {
        display: block;
    }
}