.cotizacion {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(40, 44, 52, 0.43);
    z-index: 3333;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    max-width: 700px;
    width: 100%;
    background-color: #0089cf;
    padding: 15px;
    padding-top: 40px;
    padding-bottom: 70px;
    position: relative;
}

/*@media (max-width: 714px) {*/
/*    .modal {*/
/*        overflow-y: scroll;*/
/*        height: 80vh;*/
/*    }*/

/*    .cotizacion {*/
/*        height: inherit;*/
/*    }*/
/*}*/

@media (max-height: 490px) {
    .modal {
        overflow-y: scroll;
        height: 80vh;
    }

    .cotizacion {
        height: inherit;
    }
}

.modal .cruz-cerrar {
    position: absolute;
    right: 16px;
    top: 5px;
    font-size: 30px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
}


.modal-body {
    color: white;
}

.modal-body h4 {
    font-weight: 100;
    text-align: center;
    max-width: 380px;
    display: block;
    margin: 40px auto;
}

.modal-body form {
    display: flex;
    flex-direction: column;
    max-width: 380px;
    margin: 5px auto;
}

.modal-body form input, .modal-body form textarea {
    margin-top: 7px;
    margin-bottom: 7px;
    padding-left: 8px;
    border: none;
    box-shadow: none;
    color: #00adef;
    font-family: Montserrat;
    font-weight: 200;
    padding-top: 13px;
    padding-bottom: 13px;
    outline: none !important;
}

.modal-body form input::placeholder, .modal-body form textarea::placeholder {
    color: #00adef;
}

.modal-body form button {
    background-color: #00adef;
    border: none;
    color: white;
    font-family: Montserrat;
    font-weight: 100;
    font-size: .8rem;
    padding: 10px 30px;
    cursor: pointer;
    box-shadow: 4px 9px 10px 1px #00000047;
    outline: none;
    transition: all ease 0.2s;
    align-self: center;
}

.btn-submit-container {
    margin-top: 30px;
    text-align: center;
}

.modal-body form button:hover {
    transform: scale(1.02);
}


.cotizacion p.mensaje {
    color: white;
    margin-bottom: 0;
}