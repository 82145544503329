.productos {
    position: relative;
    background-color: #0089cf;
}

.bg-productos {
    position: absolute;
    height: 100%;
    /*width: 62vw;*/
    width: calc(100vw - 34%);
    background-color: #0089cf;
    background-image: url("../images/catalogo/bg_producto.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: right;
    right: 0;
}

.productos .menu-categorias {
    color: white;
    padding-top: 35px;
}

.productos .menu-categorias.res {
    display: block;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    height: 0;
    max-height: 0;
    transition: max-height 1s ease, visibility 0s, opacity linear 1.5s;
}

.productos .menu-categorias.res.open {
    padding: 15px;
    height: 100%;
    max-height: 600px;
    visibility: visible;
    opacity: 1;

}

@keyframes slideDown{
    from {
        transform: translateY(80px);
        opacity: 0;
    }
    to {
        transform: translateY(100%);
        opacity: 1;
    }
}


.productos .menu-categorias.res li {
    font-weight: 300;
    font-size: 13px;
    font-style: inherit;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    transition: color .3s;
    position: relative;
}

.productos .btn-menu-categorias:after {
    content: '>';
    font-weight: 400;
    font-size: 17px;
    color: #00adef;
    font-style: normal;
    position: absolute;
    margin-left: 10px;
    transition: all ease .3s;
    transform: rotate(90deg);
}

.productos .btn-menu-categorias.open:after {
    transform: rotate(270deg);
}

.productos .menu-categorias h4 {
    font-weight: 200;
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 35px;
    cursor: pointer;
}

.productos .menu-categorias.res h4 {
    margin-top: 0px;
    font-size: 17px;
}

.productos .menu-categorias h5 {
    font-weight: 100;
    font-size: 13px;
    font-style: italic;
    margin-top: 5px;
    margin-bottom: 5px;
}

.productos .menu-categorias ul {
    list-style: none;
    margin-top: 5px;
}

.productos .menu-categorias li {
    font-weight: 100;
    font-size: 13px;
    font-style: italic;
    margin-top: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    -webkit-transition: color .3s;
    -moz-transition: color .3s;
    -ms-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s;
    position: relative;
}

.productos .menu-categorias li:hover {
    color: #1cccef;
}

.productos .menu-categorias .subCateg:before {
    content: '>';
    font-weight: 400;
    font-size: 15px;
    color: #00adef;
    font-style: normal;
    position: absolute;
    left: -11px;
    transition: all ease .3s;
}

.productos .menu-categorias .subCateg.selected:before {
    transform: rotate(90deg);
}

.productos .menu-categorias .subCateg.selected, .productos .menu-categorias .subCategMotores.selected {
    color: #1cccef;
}

.productos .arrancadores {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    height: 0;
}

.productos .arrancadores.selected {
    height: 100%;
    visibility: visible;
    opacity: 1;
}

.productos .arrancadores .itemMenu.selected {
    color: #1cccef;
}

.container-productos {
    padding-left: 15px;
    padding-right: 15px;
}

.grilla-productos {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 70px;
}


@media (max-width: 714px) {
    .menu-categorias {
        display: none;
    }

    .bg-productos {
        height: 100%;
        width: 100vw !important;
    }
}

@media (max-width: 995px) {
    .grilla-productos {
        justify-content: center;
    }
}

.tarjeta-producto {
    background-color: white;
    border: 1px solid #00adef;
    border-bottom: 6px solid #00adef;
    padding: 15px;
    margin: 5px;
    min-height: 300px;
}

.tarjeta-producto h6 {
    margin: 0px 8px;
    margin-top: 8px;
    max-width: 230px;
    color: #0089cf;
    font-size: 13px;
    font-weight: 400;
}

.tarjeta-producto img {
    max-width: 250px;
    margin: 0 auto;
    display: block;
}


.btn-menu-categorias {
    background-color: #081a2db5;
    color: white;
    font-family: Montserrat;
    font-weight: 100;
    text-align: center;
    padding-top: 19px;
    padding-bottom: 20px;
    display: none;
}

@media (max-width: 714px) {
    .btn-menu-categorias {
        display: block;
    }
}