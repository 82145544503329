.nav-bar {
    background-color: #0089cf;
    color: white;
    height: 85px;
}

.container-nav {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.nav-bar .logo {
    margin-right: 25px;
}

.list-nav {
    max-width: 750px;
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    height: 100%;
}

.list-nav a {
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    text-decoration: none;
    font-weight: 100;
    font-size: .7rem;
    display: flex;
    align-items: center;
    text-align: center;
}

.list-nav a:hover {
    background-color: #40b1f76b;
}

@media (max-width: 800px) {
    .list-nav a {
        font-size: .7rem;
    }
}

.burguer-menu {
    border: 1px solid white;
    border-radius: 4px;
    padding-right: 5px;
    padding-left: 5px;
    cursor: pointer;
    display: none;
}

.burguer-menu div {
    background-color: white;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media (max-width: 714px) {
    .burguer-menu {
        display: block;
    }

    .list-nav {
        display: none;
    }
}


.menu-res {
    position: fixed;
    top: 0;
    width: 80%;
    height: 100%;
    background-color: #0089cf;
    right: -100%;
    z-index: 99999999;
    padding-top: 26px;
    box-shadow: 0px 0px 11px #00000078;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
}


.menu-res.show {
    right: 0;
}

.titulo-menu-res {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.37);
    padding-bottom: 14px;

}


.titulo-menu-res h6 {
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    color: white;
}

.menu-res .list-nav-res {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.container-menu-res {
    padding-left: 25px;
    padding-right: 25px;
}

@media (max-height: 400px) {
    .menu-res .list-nav-res {
        overflow-y: auto;
        max-height: calc(100vh - 180px);
        padding-bottom: 20px;
    }
}

.menu-res .list-nav-res a {
    color: white;
    text-decoration: none;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 100;
}

@media (max-width: 714px) {
    .nav-bar .logo svg {
        max-width: 210px !important;
    }
}

.list-nav a.act {
    color: #00caef;
}