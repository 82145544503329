.icon-container{
    position: fixed;
    bottom: 5px;
    right: 15px;
    z-index: 44444;

}

.icon-container img{
    max-width: 52px;
}
